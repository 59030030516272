import React from "react"
import "./post.scss"

import Slideshow from '../../sections/slideshow'

import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../../transition/transition'


export default ({ post, next }) => {
    // console.log(post)
    const date = new Date(post.date)
    const dateString = date.getFullYear()

    const scrollToTop = () => {
        if(typeof window === "undefined") return false
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return(
        <div className="post">
            <div className="post__image">
                <Slideshow images={post.projectPage.gallery} />
            </div>
            <div className="post__header">
                <div>
                    <h2>{post.projectPage.title_extended || post.title}</h2>
                </div>
                <div>
                    <h2>{post.projectPage.subtitle}</h2>
                </div>
                <div style={{textAlign: 'right'}}>
                    <span>{dateString}</span>
                </div>
            </div>
            <div className="post__text">
                {
                    post.projectPage.sections ? 
                    post.projectPage.sections.map((section, i) => {

                        if(section.__typename === 'WPGraphQL_Post_Projectpage_Sections_Paragraph'){
                            return <Paragraph key={i} text={section.text} />
                        }else if(section.__typename === 'WPGraphQL_Post_Projectpage_Sections_Credits'){
                            return <Credits key={i} credits={section.list} />
                        }
                        
                        return false
                    })
                    : false
                }
            </div>
            <div className="post__footer">
                <div className="post__scrollTop" onClick={scrollToTop} onKeyDown={scrollToTop} tabIndex={0} role="button">Back to top</div>
                <div style={{textAlign: 'right'}}>
                    {/* <span>{post.projectPage.title_extended || post.title}</span> */}
                    <TransitionLink
                        to={`/${next.slug}`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                    Next Project
                    </TransitionLink>
                </div>
            </div>
        </div>
    )
}

const Paragraph = ({ text }) => (
    <div className="post__text__paragraph">
        <p dangerouslySetInnerHTML={{__html: text}} />
    </div>
)

const Credits = ({ credits }) => {
    if(!credits) return false

    return(
        <div className="post__text__credits">
            {
                credits.map((item, i) => {

                    return(
                        <div key={i} >
                            <p dangerouslySetInnerHTML={{__html: item.label}} />
                            <p dangerouslySetInnerHTML={{__html: item.text}} />
                        </div>
                    )
                })
            }
        </div>
    )
    
}