import React, { useState} from "react"
import { graphql } from 'gatsby'

import SEO from "../components/layout/seo"

import Post from '../components/pages/posts/post'

// Transition
import Transition from '../components/transition/transition'
import { addVisited } from '../globals/config'


export default (props) => {

  // if(!props.data.wpgraphql.posts.nodes[0]) return false
  // const title = props.data.wpgraphql.posts.nodes[0].title;
  // const post = props.data.wpgraphql.posts.nodes[0]
  if(!props.data.wpgraphql.post) return false
  const title = props.data.wpgraphql.post.title;
  const post = props.data.wpgraphql.post
  const posts = props.data.wpgraphql.posts.nodes

  // addVisited(post)
  addVisited(post)

  const [ state ] = useState({
    next: posts[Math.floor(Math.random() * posts.length)]
  })

  return (
    <div className="page__post">
      <SEO title={title} />
      <Transition>
        <Post post={post} next={state.next} />
      </Transition>
    </div>
)}

// posts(first: 200, where: {id: $id }) {



export const pageQuery = graphql`
  query currentPost($id: ID!) {
    wpgraphql{
      posts(first: 200) {
        nodes{
          slug
          title
        }
      }
      post(id: $id) {
        title
        slug
        date
        projectPage {
          subtitle
          titleExtended
          gallery{
            mediaType
            mediaItemUrl
            mediaDetails{
              width
              height
              sizes{
                sourceUrl
                width
                height
              }
            }
          }
          sections {
            ... on WPGraphQL_Post_Projectpage_Sections_Paragraph {
              text
            }
            ... on WPGraphQL_Post_Projectpage_Sections_Credits {
              list {
                label
                text
              }
            }
          }
        }
      }
    }
  }
`


// export const pageQuery = graphql`
//   query currentPost($id: String!) {
//     allWordpressPost(filter: {id: { eq: $id }}) {
//       edges {
//         node {
//           title
//           path
//           acf {
//             subtitle
//             title_extended
//             gallery{
//               media_type
//               localFile {
//                 childImageSharp {
//                   resolutions{
//                     aspectRatio
//                   }
//                   fluid(maxWidth: 1920){
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//               }
//             }
//             sections_post {
//               ... on WordPressAcf_paragraph {
//                 id
//                 text
//               }
//               ... on WordPressAcf_credits {
//                 id
//                 list {
//                   label
//                   text
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `