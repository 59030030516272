import React, { useEffect, useState} from "react"
import Swiper from 'react-id-swiper';

import LazySizes from '../layout/lazySizes'

import 'swiper/swiper.scss'
import './slideshow.scss'

const params = {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loopedSlides: 4,
    mode: 'horizontal',
    slidesPerView: "auto",
    // Disable preloading of all images
    // preloadImages: false,
    // Enable lazy loading
    // lazy: true,
    // Enable when slides perview is auto
    watchSlidesVisibility: true,
    lazy: {
        loadPrevNext: true,
    },
 
    freeMode: true,
    mousewheel: false,
    breakpoints: {
        1200: {
            freeMode: false,
        },
    },
    // runCallbacksOnInit: true,
}

const Slideshow = ({ images }) => {

     const [ state, setState ] = useState({
         mySwiper: null,
         vh: typeof window !== 'undefined' ? window.innerHeight * 0.01 : 100
     })
 
     const initSwiper = (newSwiper) => {
         let swiper = newSwiper
         setState(prevstate => ({
            ...prevstate,
            mySwiper: swiper
        }))
     }
 
     useEffect(() => {
        // console.log(state.mySwiper)
         if (state.mySwiper !== null) {
            // console.log(state.mySwiper)
             state.mySwiper.on("resize", () => {
                //  console.log("resize", window)
                 if(typeof window !== 'undefined'){
                    state.mySwiper.params.centeredSlides = window.innerWidth < 900;
                    state.mySwiper.update();
                    setState({
                        ...state,
                        vh: window.innerHeight * 0.01
                    })
                 }
             });
         }
         },
        //  [state.mySwiper]
     )

    return(
        <div>
            {images ? <Swiper { ...params } getSwiper={initSwiper} className="swiper">
            { 
                images.map((image, i) => {
                    /* Check if images have valid extension */
                    if(image.mediaType === "image"){

                        const aspectRatio = image.mediaDetails.width/image.mediaDetails.height
                        const height = `${(state.vh*100)-30}px`
                        const width = `${((state.vh*100)-30)*aspectRatio}px`

                        return (
                            <div key={i} className="swiper-slide" style={{ width, height }}>
                                <LazySizes proportional={true} image={image} vh={state.vh}/>
                            </div>
                            )

                    }else if(image.mediaType === "file"){

                        console.log("video ", image)

                        const mediaTypeAccepted = new RegExp(/\.mov|\.mp4/i)
                        if(!image.mediaItemUrl || !image.mediaItemUrl.match(mediaTypeAccepted) || !image.mediaDetails) return false

                        const aspectRatio = image.mediaDetails.width/image.mediaDetails.height
                        const height = `${(state.vh*100)-30}px`
                        const width = `${((state.vh*100)-30)*aspectRatio}px`


                        return (
                            <div key={i} className="swiper-slide" style={{ width, height }}>
                                <video muted playsInline autoPlay style={{ width, height }}>
                                image.extension === 'mov' ? <source src={image.mediaItemUrl} type="video/mov" /> : false
                                image.extension === 'mp4' ? <source src={image.mediaItemUrl} type="video/mp4" /> : false
                                </video>
                            </div>
                        )
                    }else{
                        return false
                    }
                })
            }
            </Swiper> : false}
        </div>
    )
}

export default Slideshow